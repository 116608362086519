/// <reference types="vite-plugin-svgr/client" />

import "./App.scss";

import { JuneoClient, MainNetwork, MCNProvider } from "juneojs";
import { lazy, Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AccountProvider } from "./contexts/AccountContext";
import useEnvironment from "./hooks/useEnvironment";
import { Spinner } from "./mcn-react-ui";
import AccessWallet from "./pages/Onboarding/AccessWallet/AccessWallet";
import CreateWallet from "./pages/Onboarding/CreateWallet/CreateWallet";
import WalletSetup from "./pages/Onboarding/WalletSetup/WalletSetup";
import useAccountStore from "./stores/AccountStore";
import useNetworkStore from "./stores/NetworkStore";
import useTokensStore, { getTokenArray } from "./stores/TokensStore";

const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const TermsOfUses = lazy(() => import("./pages/TermsOfUses/TermsOfUses"));
const App = lazy(() => import("./App"));

function Account() {
  const { masterWallet, isConnected, setBlockchainSelect, blockchainSelect } =
    useAccountStore();

  const {
    provider,
    setProvider,
    networksList,
    setNetwork,
    setChainDict,
    setMAX_TOTAL_STAKE,
    setMIN_TOTAL_STAKE,
    setMIN_DELEGATION_STAKE,
    setMIN_STAKE_DURATION,
  } = useNetworkStore();

  const { setTokenList } = useTokensStore();
  const environment = useEnvironment();

  // used to load the app after 0.1s
  // we load app when the user is on the onboarding page
  useEffect(() => {
    setTimeout(() => {
      import("./App");
    }, 100);
  }, []);

  useEffect(() => {
    if (masterWallet) {
      // boucle on the masterwallet to set the hrp
      masterWallet.forEach((wallet) => {
        wallet.setHrp(provider.mcn.hrp);
      });
    }
    if (!provider.mcn.primary.chains.includes(blockchainSelect)) {
      setBlockchainSelect(provider.mcn.primary.june);
    }
    setChainDict(provider.mcn.primary.chains);

    // set tokens for this network
    setTokenList(getTokenArray(provider.mcn.primary.chains, provider));

    // set for network
    setMAX_TOTAL_STAKE(provider.platformChain.stakeConfig.maxValidatorStake);
    setMIN_TOTAL_STAKE(provider.platformChain.stakeConfig.minValidatorStake);
    setMIN_DELEGATION_STAKE(
      provider.platformChain.stakeConfig.minDelegatorStake,
    );
    setMIN_STAKE_DURATION(
      Number(provider.platformChain.stakeConfig.minStakeDuration / 3600n / 24n),
    );
  }, [provider]);

  // check if the environment is production
  // if it is, set the provider to the mainnet provider
  useEffect(() => {
    if (environment === "production") {
      const mainnetNetwork = networksList.find(
        (network) => network.name === "Juneo Mainnet",
      );

      if (mainnetNetwork) {
        setProvider(
          new MCNProvider(
            MainNetwork,
            JuneoClient.parse("https://rpc-private.juneo-mainnet.network"),
          ),
        );
        setNetwork(mainnetNetwork);
      }
    }
  }, [environment]);

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          {isConnected && masterWallet ? (
            <AccountProvider masterWallet={masterWallet}>
              <App />
            </AccountProvider>
          ) : (
            <Routes>
              <Route path="/" element={<WalletSetup />} />
              <Route path="/accessWallet" element={<AccessWallet />} />
              <Route path="/createWallet" element={<CreateWallet />} />
              <Route path="/termsOfUses" element={<TermsOfUses />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          )}
        </Suspense>
      </BrowserRouter>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
}

export default Account;
