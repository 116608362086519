import "./MnemonicGrid.scss";

import React from "react";

import { CopyButton } from "../../../../mcn-react-ui";

interface MnemonicGridProps {
  mnemonicPhrase: string | undefined;
}

const MnemonicGrid: React.FC<MnemonicGridProps> = ({ mnemonicPhrase }) => {
  if (!mnemonicPhrase) {
    return null;
  }
  const words = mnemonicPhrase.trim().split(/\s+/);
  const isLargeGrid = words.length === 24;

  const rows = isLargeGrid ? 6 : 4;
  const cols = isLargeGrid ? 4 : 3;

  const numWords = rows * cols;
  const emptyWords = Array(numWords - words.length).fill("");

  // Create the mnemonic phrase with indices
  const mnemonicPhraseWithIndices = words
    .map((word, index) => `${index + 1}. ${word}`)
    .join(" ");
  // Include the empty spaces
  const finalPhraseForCopy =
    mnemonicPhraseWithIndices + " " + emptyWords.join(" ");

  return (
    <div className={`mnemonicGrid ${isLargeGrid ? "large" : "small"}`}>
      {[...words, ...emptyWords].map((word, i) => (
        <div key={i} className={`wordNr${i + 1}`}>
          <span className="wordNr">{i + 1}.</span>
          <span className="word">{word === "" ? "..." : word}</span>
        </div>
      ))}
      <span className="copyMnemonicSpan">
        <CopyButton
          message={finalPhraseForCopy}
          className="copyMnemonicButton"
          label="Copy Phrase"
          reverse
        />
      </span>
    </div>
  );
};

export default MnemonicGrid;
