import "./WalletSetup.scss";

import { Link } from "react-router-dom";

import useWindowWidth from "../../../hooks/useWindowWidth";
import FooterOnboarding from "../FooterOnboarding/FooterOnboarding";
import Root from "../Utils/Root/Root";

const WalletSetup = () => {
  const { isMobile } = useWindowWidth();
  return (
    <Root>
      {isMobile ? (
        <div className="walletSetupDiv" role="main" aria-label="Site Content">
          <div className="walletSetupTitle">
            <h1>
              This version of the Juneo Wallet is optimized for desktop use.
            </h1>
            <h1>
              For the best experience and to utilize the full range of features,
              please access the application on a computer.
            </h1>
          </div>
        </div>
      ) : (
        <div className="walletSetupDiv" role="main" aria-label="Site Content">
          <div className="walletSetupTitle">
            <h1>
              Introducing the Juneo Web Wallet for exploring possibilities of
              the Supernet platform
            </h1>
            <span>
              A non-custodial wallet, your keys – your crypto. Unlock the power
              of cross-chain transfers. Send, receive and seamless staking in
              one app. The web application for your on-chain activities.
            </span>
          </div>
          <div className="walletSetupActions">
            <div className="walletSetupRestore">
              <h2>Already have a wallet!</h2>
              <h3>Import your existing seed phrase.</h3>

              <Link
                className="walletSetupButton styledButton blue"
                to="/AccessWallet"
              >
                Access Wallet
              </Link>
            </div>
            <div className="separator"></div>
            <div className="walletSetupGenerate">
              <h2>New to the Web Wallet?</h2>
              <h3>
                Create a new wallet to send, receive and stake your assets.
              </h3>

              <Link
                className="walletSetupButton styledButton blue"
                to="/CreateWallet"
              >
                Create a New Wallet
              </Link>
              {/* <div className="overlay"></div> */}
            </div>
          </div>
        </div>
      )}
      <FooterOnboarding />
    </Root>
  );
};

export default WalletSetup;
