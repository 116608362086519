import "./Root.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

import { Logo } from "../../../../assets/svg/svgIcons";

interface RootProps {
  children: React.ReactNode;
}

const Root: React.FC<RootProps> = ({ children }) => {
  const backToHome = useNavigate();
  const onBack = () => {
    backToHome("/");
  };

  return (
    <>
      <div className="rootDiv" role="main" aria-label="Site Content">
        <div className="rootHeader">
          <Logo className="logo" onClick={onBack} />
        </div>

        {children}
      </div>
    </>
  );
};

export default Root;
