import "./PhraseInput.scss";

import React, { useState } from "react";

import { EyesClose, EyesOpen } from "../../../../assets/svg/svgIcons";

interface PhraseInputProps {
  placeholder: string;
  value: string;
  onChange: (index: number, value: string) => void;
  index: number;
}

const PhraseInput: React.FC<PhraseInputProps> = ({
  placeholder,
  value,
  onChange,
  index,
}) => {
  const [inputType, setInputType] = useState("password");

  const toggleVisibility = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(index, event.target.value);
  };

  return (
    <div className="inputGroup">
      <div className="inputFormControl">
        <label htmlFor={`phraseInput${index}`}></label>
        <input
          type={inputType}
          id={`phraseInput${index}`}
          autoComplete="off"
          spellCheck="false"
          placeholder={placeholder}
          className="inputField"
          value={value}
          onChange={handleChange}
        />
      </div>
      <div className="inputRightElement" onClick={toggleVisibility}>
        {inputType === "password" ? <EyesOpen /> : <EyesClose />}
      </div>
    </div>
  );
};

export default PhraseInput;
