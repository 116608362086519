// useEnvironment.tsx

import { useMemo } from "react";

// Define the possible environments
type Environment = "development" | "production";

const useEnvironment = (): Environment => {
  // Use useMemo to ensure that the calculation is done only once
  return useMemo(() => {
    const hostname = window.location.hostname;

    if (
      hostname === "localhost" ||
      hostname === "dev.mcnwallet.io" ||
      hostname.includes("dev") // or include dev
    ) {
      return "development";
    } else if (
      hostname === "mcnwallet.io" ||
      hostname === "www.mcnwallet.io" ||
      hostname === "juneowallet.io" ||
      hostname === "www.juneowallet.io" ||
      hostname === "juneowallet.com" ||
      hostname === "www.juneowallet.com"
    ) {
      return "production";
    }

    // Return production as fallback
    return "production";
  }, []);
};

export default useEnvironment;
