import "./CreateWalletVerify.scss";

import { MCNWallet } from "juneojs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CloseButton } from "../../../../assets/svg/svgIcons";
import { Button } from "../../../../mcn-react-ui";
import useAccountStore from "../../../../stores/AccountStore";

interface CreateWalletVerifyProps {
  setShowModal: (showModal: boolean) => void;
  newMnemonic: string;
  masterWalletToSave: MCNWallet | undefined;
}

const CreateWalletVerify: React.FC<CreateWalletVerifyProps> = ({
  setShowModal,
  newMnemonic,
  masterWalletToSave,
}) => {
  const { setMasterWallet, setIsConnected } = useAccountStore();

  const [selectedWords, setSelectedWords] = useState<(string | null)[]>(
    Array(3).fill(null),
  );
  const [wordIndices, setWordIndices] = useState<number[]>([]);
  const [error, setError] = useState(false);

  const backToHome = useNavigate();

  const closeModal = () => {
    setError(false);
    resetModal();
    setShowModal(false);
  };

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const resetModal = () => {
    setSelectedWords(Array(3).fill(null));
    setWordSets(generateWordSets(newMnemonic));
  };

  const handleWordSelection = (i: number, word: string) => {
    const newSelectedWords = [...selectedWords];
    newSelectedWords[i] = word;
    setError(false);
    setSelectedWords(newSelectedWords);
  };

  const generateRandomWordIndices = () => {
    const mnemonicWords = newMnemonic.split(" ");
    const indices = new Set<number>();
    while (indices.size < 3) {
      indices.add(Math.floor(Math.random() * mnemonicWords.length));
    }
    return Array.from(indices);
  };

  const generateWordSets = (newMnemonic: string) => {
    const mnemonicWords = newMnemonic.split(" ");
    const wordIndicesTosave = generateRandomWordIndices();
    setWordIndices(wordIndicesTosave);
    return wordIndicesTosave.map((index) => {
      const correctWord = mnemonicWords[index];
      const wordSet = new Set<string>([correctWord]);

      while (wordSet.size < 3) {
        const randomWord =
          mnemonicWords[Math.floor(Math.random() * mnemonicWords.length)];
        wordSet.add(randomWord);
      }

      // Shuffle words in wordSet
      const shuffledWordSet = Array.from(wordSet);
      for (let i = shuffledWordSet.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledWordSet[i], shuffledWordSet[j]] = [
          shuffledWordSet[j],
          shuffledWordSet[i],
        ];
      }

      return shuffledWordSet;
    });
  };

  const [wordSets, setWordSets] = useState<string[][]>(() => {
    if (newMnemonic) {
      return generateWordSets(newMnemonic);
    }
    return [];
  });

  useEffect(() => {
    if (newMnemonic) {
      setWordSets(generateWordSets(newMnemonic));
    }
  }, [newMnemonic]);

  const checkSelectedWords = () => {
    const mnemonicWords = newMnemonic.split(" ");
    return wordIndices.every(
      (index, i) => mnemonicWords[index] === selectedWords[i],
    );
  };

  const createWallet = () => {
    if (checkSelectedWords() && masterWalletToSave) {
      setMasterWallet([masterWalletToSave]);
      setShowModal(false);
      setIsConnected(true);
      backToHome("/");
    } else {
      setError(true);
    }
  };

  return (
    <>
      {newMnemonic && wordSets.length > 0 && (
        <div className="modalVerify" onClick={stopPropagation}>
          <div className="overlay"></div>

          <CloseButton className="closeIcon" onClick={closeModal} />
          {wordIndices.map((index, i) => (
            <div key={index} className="questionRow noSelect">
              <p>Select word {index + 1}</p>
              <div className="radioButs">
                {wordSets[i] &&
                  wordSets[i].map((word) => (
                    <button
                      className={`hoverBorder${
                        selectedWords[i] === word ? " selected" : ""
                      }`}
                      key={word}
                      onClick={() => handleWordSelection(i, word)}
                    >
                      {word}
                    </button>
                  ))}
              </div>
            </div>
          ))}
          {error && (
            <span className="questionRow error">
              Wrong phrase, please try again
            </span>
          )}
          <Button
            className="verifyButton"
            onClick={createWallet}
            disabled={selectedWords.includes(null)}
            width={"auto"}
            height={47}
            label="Verify"
            padding="14px 25px"
          />
        </div>
      )}
    </>
  );
};

export default CreateWalletVerify;
