import { Blockchain, MCNProvider, MCNWallet } from "juneojs";
import { create } from "zustand";

import DEFAULD_NETWORK from "../config/DefaultNetwork";

interface AccountState {
  blockchainSelect: Blockchain;
  setBlockchainSelect: (chain: Blockchain) => void;

  masterWallet: MCNWallet[] | undefined;
  setMasterWallet: (wallet: MCNWallet[] | undefined) => void;

  isConnected: boolean;
  setIsConnected: (connected: boolean) => void;
}

const useAccountStore = create<AccountState>((set) => ({
  blockchainSelect: new MCNProvider(DEFAULD_NETWORK).mcn.primary.june,
  setBlockchainSelect: (chain) => set({ blockchainSelect: chain }),

  masterWallet: undefined,
  setMasterWallet: (wallet) => set({ masterWallet: wallet }),

  isConnected: false,
  setIsConnected: (connected) => set({ isConnected: connected }),
}));

export default useAccountStore;
