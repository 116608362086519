import "./SelectWrapper.scss";

import React, { useEffect, useRef, useState } from "react";

import { ChevronDownWhite } from "../../../../assets/svg/svgIcons";

export interface SelectOption {
  value: string;
  label: string;
}

interface SelectWrapperProps {
  options: SelectOption[];
  onChange: (value: string) => void;
}

const SelectWrapper: React.FC<SelectWrapperProps> = ({ options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const selectWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectWrapperRef.current &&
        !selectWrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (value: string) => {
    setSelectedOption(value);
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div
      className={`selectWrapper ${isOpen ? "open" : ""}`}
      ref={selectWrapperRef}
    >
      <div className="selectedOption" onClick={handleToggle}>
        {selectedOption || "Select an option"}
        <ChevronDownWhite className={`arrowDown ${isOpen ? "open" : ""}`} />
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option) => (
            <div
              className="option"
              key={option.value}
              onClick={() => handleSelectOption(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectWrapper;
